import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify'
// css of react toastify
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/style.scss'

const loading = (
    <div className=" d-flex justify-content-center align-items-center" style={{ height: '100vh' }} >
        <div className="d-inline-block text-center" >
            {/* <img src={myfilogo} alt="logo" height={85} width={85}  /> */}
            <Oval height={25} />
        </div>
    </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const LoginPage = React.lazy(() => import('./container/LoginPage'))
const ForgotPasswordPage = React.lazy(() => import('./container/ForgotPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('./container/ResetPasswordPage'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
    return (
        <HashRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route exact path='/login' name="Login Page" element={<LoginPage />} />
                    <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPasswordPage />} />
                    <Route exact path="/reset-password" name="Reset Password Page" element={<ResetPasswordPage />} />
                    {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route path="*" name="Home" element={<DefaultLayout />} />
                </Routes>
            </Suspense>
            {/* toastify container */}
            <ToastContainer />
        </HashRouter>
    )
}

export default App
